import gardeny from './images/Gardeny/Cover.webp';
import routique from './images/Routique/Cover.webp';
import rusgidro from './images/Rusgidro/Cover.webp';
import terminal from './images/Terminal/Cover.webp';
import frent_lg from './images/Frent/Cover.webp';
import uritech_lg from './images/Uritech/Cover.webp';
import migrasha_lg from './images/Migrasha/Cover.webp';

import javascript from './images/iconsImages/js.svg';
import django from './images/iconsImages/django.svg';
import jQuery from './images/iconsImages/jquery.svg';
import figma from './images/iconsImages/figma.svg';
import doc from './images/iconsImages/docker.svg';
import postgresql from './images/iconsImages/postfresql-color.svg';
import flask from './images/iconsImages/flask.svg';
import reactimg from './images/iconsImages/react.svg';
import redux from './images/iconsImages/redux.svg';
import redis from './images/iconsImages/redis.svg';

import GardenymainBg4 from './images/Gardeny/mainBg4.webp';
import GardenymainBgSmall from './images/Gardeny/mainBgSmall.webp';
import GardenymainBgVertSmall from './images/Gardeny/mainBgVertSmall.webp';
import GardenymainBg768 from './images/Gardeny/mainBg768.webp';
import GardenymainBg1440 from './images/Gardeny/mainBg1440.webp';
import GardenymainBg2880 from './images/Gardeny/mainBg2880.webp';
import GardenymainBgVert1280 from './images/Gardeny/mainBgVert1280.webp';
import fullGuardCard from './images/Gardeny/Gardeny-card.webp';
import fullGuardCardSmall from './images/Gardeny/Gardeny-card-small.webp';
import fullGuard1 from './images/Gardeny/01.webp';
import fullGuard2 from './images/Gardeny/02.webp';
import fullGuard3 from './images/Gardeny/03.webp';
import fullGuard4 from './images/Gardeny/04.webp';
import fullGuard5 from './images/Gardeny/05.webp';

import RusgidromainBg4 from './images/Rusgidro/mainBg4.webp';
import RusgidromainBgSmall from './images/Rusgidro/mainBgSmall.webp';
import RusgidromainBgVertSmall from './images/Rusgidro/mainBgVertSmall.webp';
import RusgidromainBg768 from './images/Rusgidro/mainBg768.webp';
import RusgidromainBg1440 from './images/Rusgidro/mainBg1440.webp';
import RusgidromainBg2880 from './images/Rusgidro/mainBg2880.webp';
import RusgidromainBgVert1280 from './images/Rusgidro/mainBgVert1280.webp';
import rusgidroCard from './images/Rusgidro/ESG-card.webp';
import rusgidroCardSmall from './images/Rusgidro/ESG-card-small.webp';
import rusgidro1 from './images/Rusgidro/01.webp';
import rusgidro2 from './images/Rusgidro/02.webp';
import rusgidro3 from './images/Rusgidro/03.webp';
import rusgidro4 from './images/Rusgidro/04.webp';
import rusgidro5 from './images/Rusgidro/05.webp';

import TerminalmainBg4 from '../../components-services/HeaderBaner/img/mainBg4.webp';
import TerminalmainBgSmall from '../../components-services/HeaderBaner/img/mainBgSmall.webp';
import TerminalmainBgVertSmall from '../../components-services/HeaderBaner/img/mainBgVertSmall.webp';
import TerminalmainBg768 from '../../components-services/HeaderBaner/img/mainBg768.webp';
import TerminalmainBg1440 from '../../components-services/HeaderBaner/img/mainBg1440.webp';
import TerminalmainBg2880 from '../../components-services/HeaderBaner/img/mainBg2880.webp';
import TerminalmainBgVert1280 from '../../components-services/HeaderBaner/img/mainBgVert1280.webp';

import erp_terminal0Card from './images/Terminal/HeadBg-card.webp';
import erp_terminal0CardSmall from './images/Terminal/HeadBg-card-small.webp';
import erp_terminal1 from './images/Terminal/Slide/6.webp';
import erp_terminal2 from './images/Terminal/Slide/7.webp';
import erp_terminal3 from './images/Terminal/Slide/8.webp';
import erp_terminal5 from './images/Terminal/Slide/9.webp';
import erp_terminal6 from './images/Terminal/Slide/10.webp';

import RoutiquemainBg4 from './images/Routique/mainBg4.webp';
import RoutiquemainBgSmall from './images/Routique/mainBgSmall.webp';
import RoutiquemainBgVertSmall from './images/Routique/mainBgVertSmall.webp';
import RoutiquemainBg768 from './images/Routique/mainBg768.webp';
import RoutiquemainBg1440 from './images/Routique/mainBg1440.webp';
import RoutiquemainBg2880 from './images/Routique/mainBg2880.webp';
import RoutiquemainBgVert1280 from './images/Routique/mainBgVert1280.webp';
import fullRoutique0Card from './images/Routique/Routique-card.webp';
import fullRoutique0CardSmall from './images/Routique/Routique-card-small.webp';
import fullRoutique1 from './images/Routique/01.webp';
import fullRoutique2 from './images/Routique/02.webp';
import fullRoutique3 from './images/Routique/03.webp';
import fullRoutique4 from './images/Routique/04.webp';
import fullRoutique5 from './images/Routique/05.webp';

import FrentmainBg4 from './images/Frent/mainBg4.webp';
import FrentmainBgSmall from './images/Frent/mainBgSmall.webp';
import FrentmainBgVertSmall from './images/Frent/mainBgVertSmall.webp';
import FrentmainBg768 from './images/Frent/mainBg768.webp';
import FrentmainBg1440 from './images/Frent/mainBg1440.webp';
import FrentmainBg2880 from './images/Frent/mainBg2880.webp';
import FrentmainBgVert1280 from './images/Frent/mainBgVert1280.webp';
import online_rentals0Card from './images/Frent/FRENT-card.webp';
import online_rentals0CardSmall from './images/Frent/FRENT-card-small.webp';
import online_rentals1 from './images/Frent/01.webp';
import online_rentals2 from './images/Frent/02.webp';
import online_rentals4 from './images/Frent/03.webp';
import online_rentals5 from './images/Frent/04.webp';
import online_rentals6 from './images/Frent/05.webp';

import UritechmainBg4 from './images/Uritech/mainBg4.webp';
import UritechmainBgSmall from './images/Uritech/mainBgSmall.webp';
import UritechmainBgVertSmall from './images/Uritech/mainBgVertSmall.webp';
import UritechmainBg768 from './images/Uritech/mainBg768.webp';
import UritechmainBg1440 from './images/Uritech/mainBg1440.webp';
import UritechmainBg2880 from './images/Uritech/mainBg2880.webp';
import UritechmainBgVert1280 from './images/Uritech/mainBgVert1280.webp';
import uri0Card from './images/Uritech/URITECH-card.webp';
import uri0CardSmall from './images/Uritech/URITECH-card-small.webp';
import uri1 from './images/Uritech/01.webp';
import uri2 from './images/Uritech/02.webp';
import uri3 from './images/Uritech/03.webp';
import uri4 from './images/Uritech/04.webp';
import uri5 from './images/Uritech/05.webp';

import MigrashamainBg4 from './images/Migrasha/mainBg4.webp';
import MigrashamainBgSmall from './images/Migrasha/mainBgSmall.webp';
import MigrashamainBgVertSmall from './images/Migrasha/mainBgVertSmall.webp';
import MigrashamainBg768 from './images/Migrasha/mainBg768.webp';
import MigrashamainBg1440 from './images/Migrasha/mainBg1440.webp';
import MigrashamainBg2880 from './images/Migrasha/mainBg2880.webp';
import MigrashamainBgVert1280 from './images/Migrasha/mainBgVert1280.webp';
import migr0Card from './images/Migrasha/Migrants-card.webp';
import migr0CardSmall from './images/Migrasha/Migrants-card-small.webp';
import migr1 from './images/Migrasha/01.webp';
import migr2 from './images/Migrasha/02.webp';
import migr4 from './images/Migrasha/03.webp';
import migr5 from './images/Migrasha/04.webp';
import migr6 from './images/Migrasha/5.webp';
import migr8 from './images/Migrasha/8.webp';

export const filterList = [
  'Marketplace',
  'Digital trasformation',
  'E-commerce',
  'MVP',
  'Real Estate',
  'Online platform',
  'SaaS',
  'Industry',
];

export const casesList = [
  {
    seotitle: 'STEIZAR - ERP Terminal',
    seotescr: 'Discover STEIZAR in action: ERP Terminal',
    cardImg: [erp_terminal0Card, erp_terminal0CardSmall],
    bg: [
      TerminalmainBg4,
      TerminalmainBg2880,
      TerminalmainBg1440,
      TerminalmainBgVert1280,
      TerminalmainBg768,
    ],
    bgSmall: [TerminalmainBgSmall, TerminalmainBgVertSmall],
    short: {
      shortName: 'ERP Terminal',
      title: 'ERP for container terminal',
      descr: ['Logistics', 'ERP', 'Digital trasformation'],
      fullDescr: `ERP platform is designed to track container's current status in the perimeter of the terminal. Gate-in/gate-out CODECO EDI info is automatically transferred to the containerline via a built-in solution. All actions of employees are recorded in the history journal. In addition, ERP platform automatically generates invoice documents.`,
      img: [terminal, terminal],
      url: 'terminal',
    },
    full: {
      desc: `ERP platform is designed to track container's current status in the perimeter of the terminal. Gate-in/gate-out CODECO EDI info is automatically transferred to the containerline via a built-in solution. All actions of employees are recorded in the history journal. In addition, ERP platform automatically generates invoice documents.`,
      stackImgs: [javascript, flask, figma, doc, postgresql],
      mainImg: erp_terminal6,
      situation:
        'The cargo terminal has strong demand to introduce an ERP system to lower operational costs and improve security. The top management has no initial plan for how to digitalize the business logic.',
      time: '7 month',
      action: [
        'Our business consultants carried out in-house research on the terminal business processes. The business logic of solution was developed based on the information collected during the research. After approval of the draft and initial visual prototypes, our team developed a full solution, approving each product step with the client’s team.',
      ],
      result: [
        'Full coding cycle including deployment took 7 months for the execution. Our company also handled onboarding tutorials for all client employees.',
      ],
      goals: [
        'Reduce the time fortransshipment by 20%',
        'Automate document flow (shipping documents, bills of lading, etc.)',
        'Manage the processes of transshipment and transportation online',
        'Track containers in real time',
        'Track employees’ effective working time',
        'Automatically send encrypted messages in CODECO EDI format',
      ],
      images: [erp_terminal1, erp_terminal2, erp_terminal3, erp_terminal5, erp_terminal6],
    },
  },
  {
    seotitle: 'STEIZAR - Frent',
    seodescr: 'Discover STEIZAR in action: Frent',
    cardImg: [online_rentals0Card, online_rentals0CardSmall],
    bg: [FrentmainBg4, FrentmainBg2880, FrentmainBg1440, FrentmainBgVert1280, FrentmainBg768],
    bgSmall: [FrentmainBgSmall, FrentmainBgVertSmall],
    short: {
      shortName: 'Frent',
      title: 'Online platform for rentals',
      descr: ['Real Estate', 'Online platform', 'MVP', 'Blockchain'],
      fullDescr: `Your project. Your terms. Our guarantee`,
      img: [frent_lg, frent_lg],
      url: 'frent',
    },
    full: {
      desc: 'Our team has developed a web platform that completely digitalizes the relationship between tenants and landlords.A powerful solution with an integrated search engine that allows to rate counterparties, basing on a web chat with a unique business-structure. Rating system is integrated into blockchain, increasing transparency and clients’ trust.',
      stack: [
        'React',
        'React Redux',
        'Django',
        'Django REST framework ',
        'Figma',
        'Docker',
        'PostgreSQL',
      ],
      stackImgs: [reactimg, redux, django, figma, doc, postgresql],
      mainImg: online_rentals4,
      situation:
        'IT-Startup reached our team with a raw idea of a service that would make relationships between tenants and landlords transparent through a digital service. The client’s team needed assistance in conducting initial research, hypothesis testing and business consulting to from a strategy and launch an MVP.',
      time: '14 months',
      action: [
        'Our business consultants carried out a research. The business logic of solution was developed based on the information collected during the research. We proposed and designed an elaborate rating system that proved to be valuable for client segmentation. Startup team was satisfied with our workflow and overall performance through the whole project: from drafting prototypes to implementing the final solution.',
      ],
      result: [
        'Full coding cycle including deploy took 14 months for the execution. Tests of an integrated rating system show that it could immediately add value to the final product.',
      ],
      goals: [
        'Develop a user-friendly and functional design',
        'Create a multiuser platform',
        'Integrate an electronic signature block',
        'Create auto drafting and auto-completion of rental agreements',
        'Develop an IT-architecture to inform the participants of the transaction about changes in the status of the object of the contract',
      ],
      images: [online_rentals4, online_rentals1, online_rentals2, online_rentals5, online_rentals6],
    },
  },
  {
    seotitle: 'STEIZAR - Migrussia',
    seodescr: 'Discover STEIZAR in action: Migrussia',
    bg: [
      MigrashamainBg4,
      MigrashamainBg2880,
      MigrashamainBg1440,
      MigrashamainBgVert1280,
      MigrashamainBg768,
    ],
    bgSmall: [MigrashamainBgSmall, MigrashamainBgVertSmall],
    cardImg: [migr0Card, migr0CardSmall],
    short: {
      shortName: 'Migrussia',
      title: 'SaaS solution for migrants',
      fullDescr:
        'Our SaaS product for migrants is a software solution designed to provide support and assistance to individuals who are moving to a new country',
      descr: ['Legaltech', 'Online platform', 'MVP'],
      img: [migrasha_lg, migrasha_lg],
      url: 'migrasha',
    },
    full: {
      desc: `Our SaaS product for migrants is a software solution designed to provide support and assistance to individuals who are moving to a new country.
      It includes a range of features and functionality such as legal and immigration advice, job search tools, and community support resources. 
      The product is accessible throug h a web-based platform and can be used by migrants to navigate the challenges of settling into a new country, find resources and support, and access the information they need to make informed decisions.`,
      stack: [
        'Flask',
        'Figma',
        'Docker',
        'Redis',
        'WebSocket',
        'PostgreSQL',
        'JavaScript',
        'jQuery',
      ],
      stackImgs: [javascript, jQuery, flask, figma, doc, postgresql, redis],
      mainImg: migrasha_lg,
      situation:
        'A legal consulting company has identified a need in the market for a Software as a Service (SaaS) product that caters to the needs of migrants. We were hired to develop the SaaS product.',
      time: '8 months',
      action: [
        'In cooperation with our business partner we have identified the key needs of migrants and the challenges they face in their journey to a new country. This involved conducting market research and engaging with migrant communities to understand their pain points.',
      ],
      result: [
        'We have fully developed and tested the SaaS product to help migrants navigate the challenges of a new country and improve their quality of life. Steizar team has developed a powerful IT platform, combining intuitive user side for all services and hyper functional ERP for employees.',
      ],
      goals: null,
      images: [migr4, migr6, migr1, migr5, migr2, migr8],
    },
  },
  {
    seotitle: 'STEIZAR - Jurtech',
    seodescr: 'Discover STEIZAR in action: Jurtech',

    bg: [
      UritechmainBg4,
      UritechmainBg2880,
      UritechmainBg1440,
      UritechmainBgVert1280,
      UritechmainBg768,
    ],
    bgSmall: [UritechmainBgSmall, UritechmainBgVertSmall],
    cardImg: [uri0Card, uri0CardSmall],
    short: {
      shortName: 'Juritech',
      fullDescr: `Your project. Your terms. Our guarantee`,
      title: 'Online bancruptcy service',
      descr: ['Legaltech', 'Online platform', 'MVP'],
      img: [uritech_lg, uritech_lg],
      url: 'juritech',
    },
    full: {
      desc: 'Uriteh is a web-based solution that allows individuals or businesses to access legal advice and support for navigating the bankruptcy process. The platform provides a convenient and accessible solution for individuals and businesses who need to file for bankruptcy.',
      stack: [
        'React',
        'React Redux',
        'Django',
        'Django REST framework ',
        'Figma',
        'Docker',
        'PostgreSQL',
      ],
      stackImgs: [reactimg, redux, postgresql, figma, doc, django],
      mainImg: uri1,
      situation:
        'A legal consulting company has hired us to develop a platform for online bankruptcy as they wanted to expand their services and provide a more convenient and accessible solution for the clients. The situation has arisen due to the growing demand for online services and the need for remote access to legal services.',
      time: '6 months',
      action: [
        'The development of an online bankruptcy platform involved working closely with the legal consulting company to identify the specific requirements and features needed to provide a high-quality service to clients. This included features such as document sharing and collaboration tools, online appointment scheduling, secure payment processing, and an intuitive user interface that is easy to navigate and use.',
      ],
      result: [
        `We have tested extensively to ensure that the platform functions correctly and meets the needs of the legal consulting company's clients. The product is scalable and able to handle a large volume of users, as well as adaptable to future changes in bankruptcy laws and regulations.The platform meets all regulatory and legal requirements, such as complying with bankruptcy laws and regulations, ensuring that client data is kept secure and confidential, and providing clear and accurate legal advice.`,
      ],
      goals: null,
      images: [uri1, uri2, uri3, uri4, uri5],
    },
  },
  {
    seotitle: 'STEIZAR - Gardeny',
    seodescr: 'Discover STEIZAR in action: Gardeny',

    bg: [
      GardenymainBg4,
      GardenymainBg2880,
      GardenymainBg1440,
      GardenymainBgVert1280,
      GardenymainBg768,
    ],
    bgSmall: [GardenymainBgSmall, GardenymainBgVertSmall],
    cardImg: [fullGuardCard, fullGuardCardSmall],
    short: {
      shortName: 'Gardeny',
      title: 'SaaS Solution for Gardening ',
      descr: ['E-commerce', 'SaaS', 'MVP'],
      fullDescr: 'Gardeny is transforming the way gardening services are delivered and managed',
      img: [gardeny, gardeny],
      url: 'gardeny',
    },
    full: {
      desc: `The marketplace with a unique business structure and an intuitive interface. The e-commerce solution involves three groups of users: sellers, buyers and administrators.`,
      stackImgs: [javascript, django, jQuery, figma, doc, postgresql],
      mainImg: gardeny,
      situation:
        'Gardeny, an innovative startup, aimed to disrupt the gardening industry by creating a SaaS solution that connects gardeners with clients, providing on-demand gardening services. They approached STEIZAR with a visionary concept but lacked the technical expertise to turn it into a working prototype. They needed a team of skilled developers to bring their idea to life.',
      time: '7 months',
      action: [
        `Our team of experienced developers immediately dove into the project. We began by conducting a comprehensive analysis of Gardeny's requirements and objectives. In tight collaboration with the Gardeny team, we designed and developed a scalable, user-friendly platform that seamlessly connects gardeners and their clients. Service went through several UI/UX design iterations which led to mobile app capable of serving both gardeners and their clients. Key features of the app are schedule management system, earnings tracking, booking service and customizable cart.`,
      ],
      goals: [
        'The collaboration between Gardeny and our team of developers resulted in the successful creation of a working prototype of the Gardeny SaaS solution. The platform showcased:',
        '1. User-Friendly Interface: The mobile app and web portal were designed with simplicity in mind, making it easy for both clients and gardeners to use.',
        '2. Real-Time Connectivity: Gardeners could now receive job requests instantly and efficiently manage their schedules, while clients enjoyed the convenience of on-demand gardening services.',
        '3. Enhanced Efficiency: Gardeny`s prototype optimized gardening operations by connecting gardeners with nearby clients, reducing travel time and increasing job completion rates.',
        'Gardeny`s prototype garnered positive feedback from potential investors and stakeholders, paving the way for further development and scaling.',
      ],
      images: [fullGuard1, fullGuard2, fullGuard3, fullGuard4, fullGuard5],
    },
  },
  {
    seotitle: 'STEIZAR - Routique',
    seodescr: 'Discover STEIZAR in action: Routique',
    cardImg: [fullRoutique0Card, fullRoutique0CardSmall],

    bg: [
      RoutiquemainBg4,
      RoutiquemainBg2880,
      RoutiquemainBg1440,
      RoutiquemainBgVert1280,
      RoutiquemainBg768,
    ],
    bgSmall: [RoutiquemainBgSmall, RoutiquemainBgVertSmall],
    short: {
      shortName: 'Routique',
      title: 'SAAS FASHIONTECH SOLUTION',
      descr: ['ERP', 'Marketplace', 'SaaS'],
      fullDescr: 'Online Boutique Gallery with inbuilt constructor',
      img: [routique, routique],
      url: 'routique',
    },
    full: {
      desc: `Online Boutique Gallery with inbuilt constructor`,
      stackImgs: [javascript, django, flask, figma, doc, postgresql],
      mainImg: routique,
      time: '1 year',
      situation:
        'Routique, a cutting-edge FashionTech marketplace, is our own SaaS solution. We embarked on a journey to revolutionize the way brands connect with their audience. We wanted to offer brands a unique value proposition by incorporating an inbuilt landing page constructor. This feature would empower brands to create captivating and personalized landing pages, enhancing their online presence.',
      action: [
        'Before starting development process we launched an in-depth consultation phase with to finalise the business logic, target audience, and specific requirements. This process laid the foundation for a comprehensive action plan.The developers began by designing a public marketplace and a flexible and user-friendly landing page constructor. We also focused on optimizing the performance, ensuring that it could handle increased traffic and transactions. Additionally, STEIZAR implemented robust security measures to protect user data and maintain the trust of brands and consumers.',
      ],
      result: [
        'The result of the work was nothing short of remarkable. We have delivered a Minimum Viable Product (MVP) that exceeded the expectations. ',
        'The SaaS FashionTech marketplace with its inbuilt landing page constructor was an instant hit among brands and consumers alike.Key outcomes of our work included:',
        '1. Increased brand engagement and sales due to creative landing pages.',
        '2.Improved user retention and satisfaction.3.A competitive edge in the fashion tech industry.',
        '4. A robust and scalable platform that could adapt to future needs.',
        '5.Invaluable consulting on business logic that guided the growth strategy.',
      ],
      images: [fullRoutique5, fullRoutique2, fullRoutique1, fullRoutique3, fullRoutique4],
    },
  },
  {
    seotitle: 'STEIZAR - EGC ',
    seodescr: 'ERP FOR ENERGY GENERATING COMPANY',
    cardImg: [rusgidroCard, rusgidroCardSmall],

    bg: [
      RusgidromainBg4,
      RusgidromainBg2880,
      RusgidromainBg1440,
      RusgidromainBgVert1280,
      RusgidromainBg768,
    ],
    bgSmall: [RusgidromainBgSmall, RusgidromainBgVertSmall],
    short: {
      shortName: 'Feedback Solution',
      title: 'A FEEDBACK SOLUTION FOR AN ENERGY GENERATING COMPANY',
      fullDescr:
        'Revolutionized feedback collection, leading to improved customer satisfaction and operational efficiency',
      descr: ['ERP', 'Online platform', 'Industry'],
      img: [rusgidro, rusgidro],
      url: 'rusgidro',
    },
    full: {
      desc: `A Feedback Solution for an Energy Generating Company`,
      stackImgs: [javascript, flask, figma, doc, postgresql],
      mainImg: rusgidro,
      time: '3 month',
      situation:
        'Our client, an energy generating company, was facing a significant challenge in gathering and processing customer feedback. Traditional methods were time-consuming and didn`t provide real-time insights. They needed a modern solution to collect feedback efficiently and integrate it seamlessly with their ERP system.',
      action: [
        'Our team of skilled developers was brought in to address this challenge. We devised a comprehensive action plan:',
        '1. Smart Chat Integration: We developed a cutting-edge smart chat, designed to understand and respond to customer feedback effectively.',
        '2. Real-time Data Collection: The smart solution was integrated into the company`s website and other customer touchpoints. It started collecting feedback from customers in real-time, making the process convenient and instant.',
        '3. Integration with ERP System: Our developers seamlessly integrated the feedback solution with the energy company`s ERP system. This allowed customer feedback to flow directly into their internal processes for quick analysis and action.',
      ],
      result: [
        'The results of our solution were transformative:',
        '1. Enhanced Customer Satisfaction: Customers appreciated the ease of providing feedback. The energy company`s responsiveness to their concerns improved significantly.',
        '2. Operational Efficiency: The integration with the ERP system streamlined internal processes. The company could now address customer issues promptly, leading to quicker resolutions and improved operational efficiency.',
        '3. Cost Savings: The automation of the feedback collection process reduced the need for manual data entry, resulting in cost savings for the company.',
        'In conclusion, STEIZAR solution not only simplified the feedback collection process but also empowered our client to foster better customer relationships, optimize operations, and make informed decisions',
      ],

      images: [rusgidro3, rusgidro1, rusgidro2, rusgidro4, rusgidro5],
    },
  },
];
